/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

$(function() {
    "use strict";
    $(function() {
        $(".preloader").fadeOut();
    });

    // ==============================================================
    // This is for the top header part and sidebar part
    // ==============================================================
    var set = function() {
        var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
        var topOffset = 75;
        if (width < 1170) {
            $("body").addClass("mini-sidebar");
            $('.navbar-brand span').hide();
        } else {
            $("body").removeClass("mini-sidebar");
            $('.navbar-brand span').show();
        }
        var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
        height = height - topOffset;
        if (height < 1) height = 1;
        if (height > topOffset) {
            $(".page-wrapper").css("min-height", (height) + "px");
        }
    };
    $(window).ready(set);
    $(window).on("resize", set);
    // ==============================================================
    // Theme options
    // ==============================================================
    $('.left-sidebar').hover(e => {
        $('.app').toggleClass('is-collapsed');
        e.preventDefault();
    });


    //tooltip
    $(function() {
        $('[data-toggle="tooltip"]').tooltip()
    });

    //Popover
    $(function() {
        $('[data-toggle="popover"]').popover()
    });

    //Toggle password
    $('.toggle-password').on('click', function (){

        $(this).toggleClass("la-eye la-eye-slash");

        let input = $(this).parent().find("input");

        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }

    });

    //Toggle Track
    $('.toggle-track').on('change', function () {
        window.location.href = $(this).val();
    });

    let form = $('#global-user-search'),
        action = form.attr('action');

    form.validate({
        rules: {
            uid: {
                required: true,
                minlength: 3
            },
        },
        messages: {
            uid: {
                required: 'Please enter UID or name',
                minlength: 'Please enter correct UID or name'
            }

        },
        submitHandler: function (form, event) {
            event.preventDefault();

            //window.location.href = action.replace('null', $('#uid').val());
        }
    });

});
