/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

try {


    window.$ = window.jQuery = require('jquery');
    window.ko = require('knockout');
    window.date = require('date-and-time');
    window.DateTime = require('date-and-time');
    window.Highcharts = require('highcharts');
    window.Highstock = require('highcharts/highstock');

    // bootstrap
    require('jquery');
    require('bootstrap/dist/js/bootstrap.bundle');
    require('bootstrap-datepicker');
    require('jquery-validation');
    require('jquery-validation/dist/additional-methods.min');
    require('datatables.net');
    require('sortablejs');
    require('knockout.validation');


    window.toastr = require('toastr');
    global.bootbox = require('bootbox');
    window.FilePond = require('filepond');
    window.FilePondPluginFileValidateType = require('filepond-plugin-file-validate-type')
    window.FilePondPluginFileValidateSize = require('filepond-plugin-file-validate-size')

    /**
     * Default settings for Toastr.js
     * */
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    /* Jquery validation default settings */
    $.validator.setDefaults({
        onfocusout: function (e) {
            this.element(e);
        },
        onkeyup: false,
        highlight: function (element) {
            $(element).closest('.form-control').addClass('is-invalid');
        },
        unhighlight: function (element) {
            $(element).closest('.form-control').removeClass('is-invalid');
        },
        errorElement: 'div',
        errorClass: 'invalid-feedback',
        errorPlacement: function (error, element) {
            if (element.parent('.input-group-prepend').length) {
                $(element).siblings(".invalid-feedback").append(error);
                //error.insertAfter(element.parent());
            } else if (element.parent('.custom-control').length) {
                error.insertAfter($('.custom-control-label'));
            } else {
                error.insertAfter(element);
            }
        },
    });

    $.extend(true, $.fn.dataTable.defaults, {
        processing: true,
        serverSide: true,
        bInfo: true,
        language: {
            sLengthMenu: "Show _MENU_",
            processing: '<div class="py-5"><div class="spinner-border text-primary" role="status"></div></div>'
        },
    });

    /* Datepicker default settings */
    $.fn.datepicker.defaults.language = 'en';
    $.fn.datepicker.defaults.todayHighlight = true;

} catch (e) {

    console.log(e);

}
